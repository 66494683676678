import {MutableRefObject, useEffect, useRef} from "react";

interface Props {
    ref: MutableRefObject<HTMLElement | null>;
    callback: () => void;
    deps: unknown[];
    triggerOnce?: boolean;
    threshold?: number;
}

export const useCallbackOnIntersection = (props: Props) => {
    const {ref, callback, deps, triggerOnce = false, threshold = 0.5} = props;
    const preventTrigger = useRef(false);
    useEffect(() => {
        if (!window.IntersectionObserver) {
            return;
        }
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !preventTrigger.current) {
                        callback();
                        if (triggerOnce) {
                            preventTrigger.current = true;
                        }

                        // remove observer after first hit
                        if (ref.current) {
                            observer.unobserve(ref.current);
                        }
                    }
                });
            },
            // Include only elements which are visible at least in 50%;
            {threshold}
        );

        if (ref.current && !preventTrigger.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, deps);
};
