import {toNumber} from "lodash";

export function numberWithComa(x: string | number, delimiter = ","): string {
    return x.toString().split(".").join(delimiter);
}
export function roundSafely(value: string | number): number {
    if (typeof value === "string") {
        // note: this guards against "abc", but "123abc" will still pass. May need fixing.
        if (isNaN(parseFloat(value))) {
            throw new Error("Pass proper value, parseable to number");
        }

        return Math.round(parseFloat(value));
    }

    return Math.round(value);
}

export function getRoundedThousands(value: string | number): number {
    if (typeof value === "string") {
        return Math.round(parseInt(value, 10) / 1000);
    }

    return Math.round(value / 1000);
}

export enum RoundDirection {
    UP = "UP",
    DOWN = "DOWN",
    DEFAULT = "DEFAULT"
}

export function roundToNumber(value: string | number, roundTo: number, direction?: RoundDirection): number {
    const parsedValue = typeof value === "string" ? parseInt(value, 10) : value;

    switch (direction) {
        case RoundDirection.DOWN:
            return Math.floor(parsedValue / roundTo) * roundTo;
        case RoundDirection.UP:
            return Math.ceil(parsedValue / roundTo) * roundTo;
        case RoundDirection.DEFAULT:
        default:
            return Math.round(parsedValue / roundTo) * roundTo;
    }
}

export function roundDown(value: string | number): number {
    if (typeof value === "string") {
        return parseInt(value, 10);
    }

    return Math.floor(value);
}

export function sizeFormat(value: string | number) {
    if (typeof value === "string") {
        if (isNaN(parseFloat(value))) {
            throw new Error("Pass proper value, parseable to number");
        }

        const val = toNumber(value) % 1 !== 0 ? toNumber(value).toFixed(2) : toNumber(value);
        return numberWithComa(val);
    }

    return value % 1 !== 0 ? numberWithComa(value.toFixed(2)) : value;
}

export const floorFormat = (value: number) => (value === 0 ? "parter" : `${value} piętro`);

export const valueWithUnit = (unit: string) => (value: string | number) => `${value} ${unit}`;

export const sqmUnit = valueWithUnit("m2");

export const mUnit = valueWithUnit("m");
