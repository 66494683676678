import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {getAgentFullName} from "../../agent/utils/utils_agent";
import {PhoneNumberReveal} from "../../app/atoms/PhoneNumberReveal";
import {getIsPrimaryMarket} from "../../app/utils/get_is_primary_market";
import {OfferDealType, OfferMarketType, PropertyType} from "../../offer/utils/constants_offer";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../tracking/view_type/view_type";
import {IApplicationFetchOffer} from "../actions/application_fetch_offer";

export interface IVendorInfoOffer {
    agency?: {
        name: string;
        status: number;
    } | null;
    agent?: {
        name: string;
        last_name?: string;
    } | null;
    id: string;
    name: string;
    deal_type: OfferDealType;
    investment?: {
        developer?: {name: string};
        name: string;
        phone_clip?: string | null;
    } | null;
    market_type: OfferMarketType;
    property: {
        distance_from_region?: {distance?: number};
        floor?: number | null;
        location?: {path?: {name: string; type: string; slug: string}[]} | null;
        rooms?: number | null;
        room_number?: number | null;
        size: number | null;
        type: PropertyType;
    };
    slug: string;
    is_private: boolean;
    overbudget: boolean;
}
export interface IVendorInfoProps {
    agency?: {
        id: string;
        logo_picture?: {
            a_log_100: string;
        } | null;
        market_type?: OfferMarketType;
        name: string;
        slug: string;
        status: number;
        phone_number?: string;
    };
    agent?: {
        id: string;
        last_name?: string;
        name: string;
        phone_number?: string;
        picture: {
            a_img_100x100: string;
        } | null;
    };
    investment?: {
        developer?: {
            name: string;
            logo_picture?: {
                a_log_100: string;
            } | null;
            slug: string;
        } | null;
        id: string;
        name: string;
        phone_clip?: string | null;
    } | null;
    offer?: IVendorInfoOffer | null;
    className?: string;
    isOfferListModal?: boolean;
    gtmSource: GtmSource;
    viewType: ViewType | null;
    voivodeshipSlug?: string;
    hidePhoneNumber: boolean | undefined;
    hasOfferResponse?: IApplicationFetchOffer | null;
    openModal?: () => void;
}

const IMAGE_SIZE = 70;

const VendorPictureComponent = (props: {vendorPicture: string; vendorName: string; isAgent?: boolean}) => (
    <VendorImage isAgent={props.isAgent}>
        <img src={props.vendorPicture} alt={props.vendorName} width={IMAGE_SIZE} loading="lazy" />
    </VendorImage>
);

const getInitials = (name: string, lastName?: string) => (lastName ? `${name.charAt(0)}${lastName.charAt(0)}` : name.substring(2, 0));

export const VendorInfo = (props: IVendorInfoProps) => {
    const {agent, className, agency, investment} = props;
    const investmentPhoneClip = investment?.phone_clip || props.offer?.investment?.phone_clip;

    const phoneNumber = agent?.phone_number || agency?.phone_number || investmentPhoneClip;
    // all offers from primary market should show company info, even agencies, agent info is not allowed there
    const isPrimaryMarket = getIsPrimaryMarket(props);

    // vendor data can come from investment developer details or can be stored in 'agency' object (also for developers!) depending on current view
    const vendorData = investment?.developer ? investment?.developer : agency;

    // pictures
    const agentPicture = agent?.picture?.a_img_100x100 || null;
    const vendorPicture = vendorData ? vendorData.logo_picture?.a_log_100 : null;

    // names
    const companyName = vendorData?.name;
    const agentFullName = getAgentFullName(agent);
    const privateOfferOwnerName = agent && props.offer?.is_private ? agent.name : null;

    // some aftermarket agencies have only one `agent` with no name - show company logo in this case
    const hasAgent = agent && agent.id && agentFullName;
    const hasPicture = !!(!isPrimaryMarket && hasAgent) || !!((isPrimaryMarket || !hasAgent) && vendorPicture);
    const isPhoneEnabled = (!!phoneNumber && !props.hidePhoneNumber) || false;
    const printVendorName = props.offer && props.offer.is_private ? privateOfferOwnerName : agentFullName || companyName;
    return (
        <Wrapper className={className} hasPicture={hasPicture}>
            <InnerInfoWrapper hasPicture={hasPicture}>
                {!isPrimaryMarket &&
                    hasAgent &&
                    agentFullName &&
                    (agentPicture ? (
                        <VendorPictureComponent vendorPicture={agentPicture} vendorName={printVendorName ?? agent.name} isAgent />
                    ) : (
                        <VendorImage css={agentInitialsHolder} isAgent>
                            {getInitials(agent?.name, agent?.last_name)}
                        </VendorImage>
                    ))}

                {(isPrimaryMarket || !hasAgent) && vendorPicture && companyName && (
                    <VendorPictureComponent vendorPicture={vendorPicture} vendorName={companyName} />
                )}

                <VendorTextInfo>
                    <VendorName isPhoneEnabled={isPhoneEnabled} hasPicture={hasPicture}>
                        {printVendorName}
                    </VendorName>
                    {isPhoneEnabled && (
                        <PhoneNumberReveal
                            offer={props.offer}
                            agent={props.agent}
                            agency={props.agency}
                            investment={props.investment}
                            isOfferListModal={props.isOfferListModal}
                            gtmSource={props.gtmSource}
                            viewType={props.viewType}
                            voivodeshipSlug={props.voivodeshipSlug}
                            center={!hasPicture}
                            openModal={props.openModal}
                        />
                    )}
                </VendorTextInfo>
            </InnerInfoWrapper>
        </Wrapper>
    );
};

/*
Styles
 */

interface IHasPicture {
    hasPicture: boolean;
}

const Wrapper = styled.div<IHasPicture>`
    margin: 0 auto ${(props) => (props.hasPicture ? "1.5rem" : "2.5rem")};

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        max-width: 260px;
    }
`;

export const InnerInfoWrapper = styled.div<IHasPicture>`
    text-align: ${(props) => (props.hasPicture ? "left" : "center")};
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${(props) => (props.hasPicture ? "flex-start" : "center")};

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        max-width: 360px;
    }
`;

interface IVendorName {
    isPhoneEnabled: boolean;
    hasPicture: boolean;
}

const VendorName = styled.p<IVendorName>`
    color: ${(props) => props.theme.colors.gray_dark};
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.5;
    margin: ${(props) => (props.isPhoneEnabled ? `-1.1rem` : 0)} 0 ${(props) => (props.isPhoneEnabled ? `1.5rem` : 0)} 0;
    text-align: ${(props) => (props.isPhoneEnabled && props.hasPicture ? `left` : "center")};
`;

interface IFormVendorImage {
    isAgent?: boolean;
}

const VendorImage = styled.div<IFormVendorImage>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    width: ${IMAGE_SIZE}px;
    height: ${IMAGE_SIZE}px;
    border-radius: ${(props) => (props.isAgent ? "50%" : props.theme.other.border_radius)};
    margin: 0 2rem 0 0;
`;

const agentInitialsHolder = (theme: Theme) => css`
    background-color: ${theme.colors.brand_primary_lighter};
    font-weight: 500;
    font-size: 2.4rem;
    text-transform: uppercase;
`;

const VendorTextInfo = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
`;

export const ApplicationRodoTermsParagraph = styled.p`
    color: ${(props) => props.theme.colors.gray_brighter};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.58;
    padding: 0;
    margin: 1.5rem 0;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        margin: 2rem 0 0.7rem;
    }
`;
