import * as lscache from "lscache";

import {UTM_DATA_LS_KEY, UTM_DATA_TIME_LS_KEY} from "../../src/app/constants/storage_keys";
import {isBrowser} from "../../src/app/utils/read_environment_variables";
import {parseSearch} from "../../src/app/utils/request_response_utils/parse_search";

interface IEligibleParams extends IUtmParams {
    gclid: string; // special Google Ads param; Google does not use UTM params
}

interface IUtmParams {
    utm_source: string; // "google_ads", "facebook", "Lifull-connect"
    utm_medium: string; // none, "paid", "CPC"
    utm_campaign: string; // none, campaign name, "premium"
}

const HOUR_IN_MINUTES = 60;
const MONTH_IN_MINUTES = 30 * 24 * 60; // one full month (30 days) expressed as a number of minutes

export const cacheUtmData = () => {
    if (!isBrowser) {
        // this is a client-side script
        return;
    }

    const params: Partial<IEligibleParams> = parseSearch(window.location.search);
    const utms: Partial<IUtmParams> = {
        ...(params.utm_source ? {utm_source: params.utm_source} : {}),
        ...(params.utm_medium ? {utm_medium: params.utm_medium} : {}),
        ...(params.utm_campaign ? {utm_campaign: params.utm_campaign} : {})
    };
    // Google Ads logic
    if (params.gclid) {
        utms.utm_source = "google_ads";
    }
    // save to LS
    if (Object.keys(utms).length) {
        if (lscache.get(UTM_DATA_TIME_LS_KEY)) {
            // when this value exists it means that it was less than one hour ago that the previous UTMs were set
            return;
        }
        const timestamp = new Date().getTime(); // there is no need to save timestamp, but we have to save something and timestamp may be useful
        lscache.set(UTM_DATA_TIME_LS_KEY, timestamp, HOUR_IN_MINUTES);
        lscache.set(UTM_DATA_LS_KEY, utms, MONTH_IN_MINUTES);
    }
};

export const getCachedUtmData = (): Partial<IUtmParams> => lscache.get(UTM_DATA_LS_KEY);
