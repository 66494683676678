import {IGtmProduct} from "../utils/get_gtm_product";
import {GtmContactType} from "../utils/gtm_contact_type";
import {GtmSource} from "../utils/gtm_source";
import {gtmEcommerceEventWithOffer, gtmEcommerceEventWithoutOffer, IEventWithOfferProps, IEventWithoutOfferProps} from "./gtm_event_with_offer";

interface IGtmCheckout {
    event: "checkout";
    contactTypeHit?: GtmContactType;
    ecommerce: {
        checkout: {
            actionField: {
                step: 1;
            };
            products: [IGtmProduct];
        };
    };
}

export const gtmCheckoutWithOffer = (props: IEventWithOfferProps) => {
    gtmEcommerceEventWithOffer(props, getGtmCheckoutData);
};

export const gtmCheckoutWithoutOffer = (props: IEventWithoutOfferProps) => {
    gtmEcommerceEventWithoutOffer(props, getGtmCheckoutData);
};

const getGtmCheckoutData = (product: IGtmProduct, props: IEventWithOfferProps | IEventWithoutOfferProps): IGtmCheckout => ({
    event: "checkout",
    contactTypeHit: props.contactType,
    ecommerce: {
        checkout: {
            actionField: {
                step: 1
            },
            products: [product]
        }
    }
});
